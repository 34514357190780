@charset "gb2312";

body {
    font: 12px/24px \5B8B\4F53;
    color: #484848
}

body,
html,
a,
img,
div,
form,
select,
input,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
dd,
dl,
dt,
p,
label,
em,
span,
cude,
i {
    margin: 0;
    padding: 0
}

html,
body {
    overflow-x: hidden
}

/* body {
    position: relative
} */

.skin-lk {
    display: none
}

.skin-lk a {
    background: url(about:blank);
    display: block;
    width: 210px;
    height: 720px;
    overflow: hidden;
    text-indent: -9999em;
    line-height: 99;
    position: absolute;
    left: 50%;
    top: 40px
}

.skinlk-r {
    margin-left: 490px
}

.skinlk-l {
    margin-left: -700px
}

a {
    color: #484848;
    text-decoration: none
}

a:hover {
    color: #fb6400;
    text-decoration: underline
}

ol,
ul,
li {
    list-style: none
}

em,
i {
    font-style: normal
}

img {
    border: none;
    display: block
}

.cf {
    *zoom: 1
}

.cf:after {
    content: '';
    display: block;
    height: 0;
    clear: both
}

.fl,
.link_w,
.link_w li,
.search,
.search input,
.s_fl,
.link_r a,
.acc img,
.acc span,
.login img,
.login span,
.ico_4,
.ico_5,
.not_ul,
.not_ul li,
.pag,
.pag a,
.p_up,
.p_do,
.menu_le,
.menu_le a,
.menu_ri a,
.for_phone,
.mi_ul,
.mi_ul li,
.mi_ul em,
.p-ico,
.logo,
.tdc,
.reco,
.reco_r a,
.reco em,
.reco_c,
.reco_c a,
.idesk a,
.mi_d,
.mi_web,
.mi-lr,
.mi_tit,
.game_w,
.h5_w,
.ip_game,
#hscontent,
#hscontent img,
.hsl_n,
.hsl_y,
.hsr_n,
.hsr_y,
.ulleft,
.g_le,
.game_box,
.g_list,
.g_list li,
.box_l,
.box_c,
.s_tab1,
.s_tab1 li,
.rec_ul,
.rec_ul li,
.rec_img,
.rec_ul p,
.tit_ico1,
.tit_ico2,
.tit_ico3,
.tit_ico4,
.phone,
.tit_a,
.evn_ul,
.evn_ul li,
.tm_list li,
.top_ul li,
.qz_ul,
.qz_ul li,
.qz_img,
.qz_ul p,
.wd_ul,
.wd_ul li,
.g-top .tit,
.g-le,
.w-game li,
.old-line,
.or-ul,
.or-ul li,
.fenl_lbox,
.fenl_ul li,
.s_tab,
.s_tab li,
.s_le,
.lt-l,
.lt-c,
.lt-r,
.tit-n em,
.tit-n .tit,
.lt-c .tex em,
.lt-tab,
.lt-tab a,
.lt-list li,
.video li,
.games_ul,
.games_ul li,
.skin_tab,
.skin_tab a,
.skin_ul li,
.skin_pag,
.skin_pag a,
.sk_le,
.sk_ri,
.skin_btn a,
.new_ico,
.top-tit i,
.top-tit .a,
.phone_ul li,
.lt-list,
.v-top li,
.v-top em,
.v-top .img,
.v-top .tex,
.v-top .btn,
.tm_list {
    float: left;
    display: inline
}

.fr,
.link_r,
.dl,
.menu_ri,
.reco_r,
.idesk,
.more,
.more_1,
.caro,
.empty,
.g_ri,
.g-ri,
.tit_ico5,
.more_2,
.more_3,
.tm_upd,
.old-ri,
.fenl_rbox,
.s_ri,
.lt-c .tex,
.lt-r,
.mi_d,
.p_close,
.skin_btn,
.s-link,
.lt-r1,
.v-top {
    float: right;
    display: inline
}

.link_w li,
.acc span,
.login a,
.login span,
.ico_4,
.ico_5,
.not_ul li,
.pag a,
.new_pag,
.top,
.menu_le,
.menu_ri,
.middle_1,
.for_phone,
.mi_ul em,
.p-forum,
.reco_c,
.idesk a,
.mi_tit,
.mi_tit,
.game_w,
.h5_w,
.more_1,
.caro,
.caro li,
.bgbox,
#hscontent,
.mask,
#hscontentdiv span,
.hsl_n,
.hsl_y,
.hsr_n,
.hsr_y,
.game_box,
.g_list li,
.s_ico,
.rec_ul li,
.evn_week,
.evn_ul li,
.more_2,
.more_3,
.tm_list li,
.top_ul li,
.qz_ul,
.qz_ul li,
.wd_ul li,
.g-le,
.g-ri,
.settit,
.tm_tit,
.top_tit,
.old-line,
.old-ri,
.or-ul li,
.fenl_ul li,
.s_tab li,
.s_ri,
.lt-c .tex span,
.video li,
.games_ul li,
.copy_c,
.skin_tab,
.s-box a,
.w-game li,
.link,
.lt-r,
.lt,
.video,
.settit a,
.boxes,
.lr-line,
.lt-list li,
.setimgs,
.top-tit,
.top-tit i,
.middle_2,
.phone_ul li,
.v-top li,
.wechat-QR {
    overflow: hidden
}

.acc img,
.not_ul li,
.menu,
.mi_ul li,
.iptg,
.mi_b,
.mi_1,
.tab_1,
.tm_list li,
.setlide,
.s-tex,
.old-g,
.fenl_ul li,
.lt-list li,
.video li,
.games,
.copy_c,
.skin_ul li,
.boxes,
.copy {
    position: relative
}

.hot,
.new,
.com,
.cla,
.myhot,
.mynew,
.i2_hover,
.i6_hover,
.login,
.played,
.i_rec,
.i_close,
.h,
.n,
.i_hot,
.i_new,
.mask,
.s_tab1,
.g_line,
.setlide .up,
.setlide .do,
.settit,
.s-tex a,
.g-ico,
.o-tit,
.lt-list a em,
.video a em,
.skin_pop,
.skin_box,
.i_sel,
.corner,
.tns,
.prompt,
.p-bg,
.wechat-QR {
    position: absolute
}

.hot,
.new,
.com,
.cla,
.myhot,
.mynew,
.s_btn,
.login span,
.i_close,
.mi_ul em,
.reco em,
.mi_b,
.mi_1,
.ip_game span,
.clo,
.hsl_y,
.hsr_y,
.s_tab1 li,
.new_ico,
.s_tab li,
.lt-list a em,
.video a em,
.i_sel,
.corner,
.tns,
.clo {
    cursor: pointer
}

.hot,
.new,
.com,
.myhot,
.mynew,
.login a,
.i_rec,
.mi_ul a,
.h,
.n,
.i_hot,
.i_new,
.mask,
.ulleft,
.s_ico,
.asp_img,
.g_line,
.g-le em,
.setlide .up,
.setlide .do,
.s-tex a,
.old-a,
.lt-c .tex span,
.lt-list a em,
.video a em,
.i_sel,
.s-box a,
.g-box,
.corner,
.settit a,
.boxes,
.tns,
.prompt,
.lt-list .a {
    display: block
}

.wrap,
.not_p,
.not_p1,
.not_ul img,
.top,
.menu,
.middle_1,
.p-forum,
.middle_2,
.tdc img,
.bgbox,
.rollgame,
.g_list img,
.s_ico,
.tm_list img,
.top_ul img,
.g-zone,
.g-cen,
.old-a img,
.fenl_ul img,
.lt,
.copy_t,
.top-tit,
#rel_pagplay,
#rel_pag,
.phone_ul img {
    margin: 0 auto
}

.menu,
.evn_week,
.g_line,
.g-top,
.g-zone .t1,
.g-zone .t2,
.link,
.tm_tit,
.top_tit,
.s_tab li,
.tit-n .tit,
.s_tab1,
.top-tit,
.ip_game,
.v-top .c1,
.v-top .c2,
.v-top .c3 {
    font-family: \5FAE\8F6F\96C5\9ED1, \5B8B\4F53
}

.menu,
.s_tab1,
.evn_week,
.qz_ul p,
.g_line,
.g-top,
.g-zone .t1,
.g-zone .t2,
.link a,
.tm_tit,
.top_tit,
.old-a,
.s_tab li,
.tit-n .tit,
.skin_tab a,
.skin_btn a,
.top-tit .a,
.ip_game,
.v-top li {
    font-size: 14px
}

.quit,
.not_p,
.not_p1,
.not_ul li,
.new_pag,
.menu_le a,
.for_phone,
.tdc,
.empty,
.g_list li,
.g_list li,
.evn_ul li,
.tm_list li,
.top_ul li,
.wd_ul li,
.g_line,
.w-game li,
.settit,
.s-tex a,
.old-a,
.or-ul li,
.fenl_ul li,
.s_tab li,
.lt-tab a,
.lt-list a em,
.video li,
.video a em,
.links,
.copy,
.copy_c,
.skin_tab a,
.skin_ul li,
.skin_pag a,
.s-box a,
.s_tab1 li.tab_on,
.skin_btn a,
.s_tab1 li,
.p-bg,
.game_w,
.h5_w,
.phone_ul li,
.v-top em {
    text-align: center
}

.menu_le a:hover,
.m_on,
.empty:hover,
.lt-tab a:hover,
.lt-tab a.on,
.lt-list a em,
.video a em,
.s-box a:hover,
.lr-line a:hover {
    text-decoration: none
}

.menu_le a,
.empty:hover,
.settit,
.settit a,
.lt-c .tex em,
.lt-tab a:hover,
.lt-tab a.on,
.lt-list a em,
.video a em,
.skin_pag a:hover,
.s_on,
.s-box a:hover,
.lr-line a:hover,
.ip_game a,
.ip_game:hover,
.v-top em {
    color: #fff
}

.up_1,
.do_1,
.mi_b,
.mi_1,
.ip_game span,
#hscontentdiv a,
#hscontentdiv span,
.g-zone .t1,
.g-zone .t2,
.old-line a,
.lr-line a,
.fk em,
.wb,
.tx,
.ghs,
.moc,
.mi_d1 span,
.mi_d2 span,
.mi_d3 span {
    display: inline-block
}

.nav_top,
.back,
.i2_hover,
.i6_hover,
.ico_1,
.ico_2,
.ico_3,
.ico_4,
.ico_5,
.ico_6,
.a_01,
.a_02,
.a_03,
.a_04,
.r_up,
.r_do,
.not_p,
.d-line,
.menu_le a:hover,
.m_on,
.i_a1,
.i_a2,
.i_a3,
.i_hot,
.i_new,
.tit_ico3,
.tit_ico4,
.game_w,
.h5_w,
.more_top a,
.p_up,
.p_do,
.pag a,
.pag a:hover,
.p_on,
.ip_game span,
.caro,
.new_ico,
.more_2,
.more_3,
.moc,
.fk em,
.wb,
.tx,
.skin_top,
.i_sel,
.p_close,
.skin_pag a,
.sk_le,
.sk_ri,
.dr,
.new,
.hot,
.com,
.cla,
.i_rec,
.i_close,
.h,
.n,
.up_1,
.do_1,
.p-ico,
.ghs,
.g-top,
.g-zone .t1,
.g-zone .t2,
.setlide .up,
.setlide .do,
.skin_tab a:hover,
.skin_tab .on,
.top-tit i {
    background: url(../../assets/images/i2017/fixed12.png)
}

.search,
.menu,
.menu_ri,
.gp:hover,
.dh:hover,
.mi_tit,
.ip_game,
.hsl_n,
.hsl_y,
.hsr_n,
.hsr_y,
.g_le,
.g_ri,
.tm_la,
.more_1,
.tit_ico1,
.tit_ico2,
.tit_ico5,
.phone,
.qz_ul li,
.more_top:hover,
.tab_on,
.star_tab,
.s_ri,
.copy_t,
.t_top,
.for_phone,
.s_ico,
.g-ico,
.old-t1,
.old-t2,
.s_tab li.tab_on,
.s_le,
.tit-n em,
.lt-tab a {
    background: url(../../assets/images/i2017/color_1.png)
}

.hot,
.new,
.com,
.cla {
    width: 38px;
    height: 38px;
    top: 0;
    left: 9px
}

.hot {
    background-position: -346px -393px
}

.new {
    background-position: -346px -431px
}

.com {
    background-position: -346px -455px;
    width: 36px;
    height: 36px
}

.cla {
    background-position: -397px -393px
}

.myhot,
.mynew {
    width: 20px;
    height: 14px;
    right: 0;
    top: 2px
}

.myhot {
    background: url(http://imga.4399.cn/upload_pic/2013/hot3.gif) no-repeat right 0
}

.mynew {
    background: url(http://imga.4399.cn/upload_pic/2013/new3.gif) no-repeat right 0
}

.tns {
    background: url(../../assets/images/i2016/news.png) no-repeat;
    width: 29px;
    height: 19px;
    top: -6px;
    right: 0
}

.nav_box {
    height: 31px
}

.nav_top {
    width: 100%;
    height: 31px;
    position: fixed;
    z-index: 1001
}

.wrap {
    width: 980px;
    z-index: 10
}

.wrap a {
    color: #8e8e8e
}

.wrap a:hover {
    color: #f60
}

.link_w {
    padding-top: 8px
}

.link_w li {
    height: 16px;
    line-height: 16px;
    border-right: 1px solid #e0e0e0;
    margin-right: 10px;
    padding-right: 10px
}

.back {
    background-position: -106px -228px;
    padding-left: 22px;
    border-right: 0 !important
}

.search {
    width: 280px;
    height: 24px;
    margin-top: 3px
}

.search input {
    width: 205px;
    height: 24px;
    line-height: 26px;
    padding-left: 25px;
    background: none;
    border: none;
    color: #bcbcbc
}

.s_btn {
    width: 50px !important
}

.link_r {
    height: 23px;
    padding-top: 7px
}

.link_r a {
    height: 16px;
    line-height: 17px;
    border-right: 1px solid #e0e0e0;
    margin-right: 10px;
    padding-right: 8px;
    display: inline
}

.acc img {
    margin: -4px 5px 0 0;
    width: 24px;
    height: 24px;
    z-index: 899
}

.acc span {
    height: 14px;
    width: 48px;
    line-height: 14px;
    padding-top: 2px
}

.ico_1 {
    background-position: -106px -250px;
    padding-left: 23px
}

.ico_2 {
    background-position: -106px -276px;
    padding: 0 23px !important;
    color: #FB6400 !important
}

.ico_6 {
    background-position: -460px -393px;
    padding: 0 23px !important
}

.i2_hover,
.i6_hover {
    height: 32px;
    line-height: 31px;
    padding: 0 10px 0 33px;
    width: 61px;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    z-index: 2
}

.i2_hover {
    background-position: -96px -269px;
    background-color: #fff;
    margin-left: 313px;
    color: #FB6400 !important
}

.i6_hover {
    background-position: -450px -386px;
    background-color: #fff;
    margin-left: 208px
}

.top-tit {
    width: 250px;
    height: 16px;
    line-height: 16px;
    padding-top: 15px
}

.top-tit i {
    background-position: -158px -69px;
    width: 4px;
    height: 16px
}

.top-tit .a {
    color: #448230;
    margin-left: 8px;
    font-weight: bold
}

.ico_3 {
    background-position: -106px -302px;
    padding-left: 23px;
    border-right: 0 !important;
    padding-right: 0 !important;
    color: #0098DF !important
}

.s_em {
    padding: 1px 2px
}

.login {
    width: 108px;
    background: #fff;
    top: -2px;
    left: 50%;
    margin-left: 99px;
    border: 1px solid #ccc;
    border-top: 0;
    padding-top: 3px;
    z-index: 11
}

.login a {
    height: 30px;
    line-height: 30px
}

.login img {
    margin: 2px 5px 0 8px;
    width: 24px;
    height: 24px
}

.login span {
    width: 48px;
    height: 24px
}

.ico_4,
.ico_5 {
    background-position: -192px -331px;
    width: 10px;
    height: 5px
}

.ico_4 {
    margin: 12px 0 0 5px
}

.ico_5 {
    margin: 6px 0 0 5px
}

.a_01,
.a_02,
.a_03,
.a_04 {
    background-repeat: no-repeat;
    padding-left: 31px
}

.a_01 {
    background-position: 7px -415px
}

.a_01:hover {
    background-position: -131px -415px
}

.a_02 {
    background-position: 7px -441px
}

.a_02:hover {
    background-position: -131px -441px
}

.a_03 {
    background-position: 7px -468px
}

.a_03:hover {
    background-position: -131px -468px
}

.a_04 {
    background-position: 7px -500px
}

.a_04:hover {
    background-position: -131px -500px
}

.quit {
    background: #f5f5f5
}

.played {
    background: #fff;
    width: 280px;
    border: 1px solid #ccc;
    left: 50%;
    margin-left: 208px;
    top: 31px;
    padding-bottom: 10px;
    z-index: 1
}

.p_ml {
    margin-left: 113px
}

.not_p,
.not_p1 {
    background-position: -622px -269px;
    width: 276px;
    height: 45px;
    line-height: 45px;
    color: #fb6400;
    overflow: hidden
}

.not_p1 {
    height: 30px
}

.not_ul {
    padding: 15px 0 0 11px
}

.not_ul li {
    width: 78px;
    height: 100px;
    margin: 0 11px 3px 0
}

.not_ul img {
    width: 72px;
    height: 72px
}

.i_rec {
    background-position: -461px -424px;
    width: 20px;
    height: 14px;
    left: 4px;
    top: 5px
}

.i_close {
    background-position: -461px -448px;
    display: none;
    width: 11px;
    height: 11px;
    right: 3px;
    top: 0
}

.d-line {
    background-position: -622px -196px;
    height: 118px
}

#rel_pagplay,
#rel_pag {
    width: 85px;
    height: 17px
}

.pag a {
    background-position: -360px -266px;
    width: 7px;
    height: 7px;
    margin: 6px 5px 0 5px
}

.pag a:hover,
.p_on {
    background-position: -360px -273px !important
}

.p_up,
.p_do {
    width: 17px;
    height: 17px
}

.p_up {
    background-position: -360px -231px
}

.p_up:hover {
    background-position: -360px -248px
}

.p_do {
    background-position: -377px -231px
}

.p_do:hover {
    background-position: -377px -248px
}

.dl {
    color: #666
}

.dl:hover {
    color: #fb6400 !important
}

.new_pag {
    clear: both;
    height: 22px
}

.up_1,
.do_1 {
    width: 36px;
    height: 20px;
    margin: 0 5px
}

.up_1 {
    background-position: -908px -387px
}

.up_1:hover,
.up_1.uon {
    background-position: -908px -407px !important
}

.do_1 {
    background-position: -944px -387px
}

.do_1:hover,
.do_1.don {
    background-position: -944px -407px !important
}

.top,
.menu {
    width: 978px
}

.top {
    background: #fff;
    height: 75px
}

.menu {
    background-position: 0 -24px;
    background-color: #6cae05;
    height: 34px;
    border: 1px solid #5f9b01
}

.menu_le {
    padding: 5px 0 0 5px;
    height: 29px
}

.menu_le a {
    // width: 40px;
    height: 25px;
    line-height: 25px;
    margin-left: 2px
}

.menu_le a:hover,
.m_on {
    background-position: -910px -231px;
    color: #6daf05 !important;
    font-weight: bold
}

.menu_ri {
    background-position: 0 -58px;
    width: 139px;
    padding-left: 10px;
    height: 34px;
    line-height: 32px
}

.menu_ri a {
    margin: 0 7px;
    font-weight: bold;
    _font-weight: normal;
    color: #6aa905
}

.menu_ri a:hover {
    color: #6aa905
}

.middle_1 {
    background: #eeffd9;
    width: 978px;
    height: 34px;
    background: #eeffd9;
    border-left: 1px solid #c0eb7c;
    border-right: 1px solid #c0eb7c
}

.for_phone {
    background-position: -61px -224px;
    width: 42px;
    padding-right: 5px;
    height: 34px;
    line-height: 34px;
    color: #389904
}

.mi_ul {
    padding-top: 5px
}

.mi_ul li {
    line-height: 24px;
    padding: 0 9px 0 6px;
    margin-right: 20px;
}

.mi_ul em {
    width: 24px;
    height: 24px;
    margin-right: 4px
}

.mi_ul a {
    color: #fb6400;
    font-weight: bold
}

.mi_ul a:hover {
    color: #fb6400
}

.h,
.n {
    width: 10px;
    height: 13px;
    right: 0;
    top: -3px
}

.h {
    background-position: -461px -494px
}

.n {
    background-position: -461px -516px
}

.p-forum {
    background: #fff;
    width: 978px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #c0eb7c;
    border-top: none
}

.p-ico {
    background-position: -396px -231px;
    width: 99px;
    height: 34px
}

.p-forum a {
    margin-right: 12px
}

.iptg {
    background: #fff;
    border: 1px solid #c0eb7c;
    width: 978px;
    height: 42px;
    line-height: 42px;
    font-size: 12px;
    margin: 0 auto 5px
}

.middle_2 {
    background: #fff;
    border: 1px solid #c0eb7c;
    width: 978px;
    height: 225px;
    padding-top: 3px
}

.logo {
    margin-top: 5px
}

.tdc {
    width: 72px;
    color: #666;
    line-height: 18px;
    margin: 3px 0 0 15px
}

.tdc img {
    width: 53px;
    height: 53px
}

.reco {
    width: 502px;
    margin: 10px 0 0 20px
}

.reco em {
    width: 9px;
    height: 14px;
    margin-top: 20px
}

.r_up {
    background-position: -368px -266px;
    margin-right: 5px
}

.r_do {
    background-position: -377px -266px;
    margin-left: 5px
}

.reco_c {
    width: 460px;
    height: 55px
}

.reco_c a {
    width: 70px;
    height: 55px;
    margin: 0 11px
}

.reco_c a img {
    width: 70px;
    height: 55px
}

.reco_r {
    padding: 10px 2px 0 0
}

.reco_r a {
    margin-left: 10px
}

.reco_r img {
    width: 85px;
    height: 55px
}

.dh {
    width: 55px !important
}

.dh:hover {
    background-position: -910px -257px !important
}

.gp {
    width: 70px !important
}

.gp:hover {
    background-position: -910px -283px !important
}

.i_hot,
.i_new {
    background-position: -360px -284px;
    width: 29px;
    height: 19px;
    right: 230px;
    top: -8px
}

.i_new {
    background-position: -391px -284px;
    width: 17px;
    height: 17px;
    right: 336px
}

.idesk {
    width: 253px;
    margin: 5px 5px 0 0
}

.idesk a {
    width: 114px;
    height: 24px;
    margin-left: 5px;
    line-height: 100px
}

.i_a1 {
    background-position: -212px -228px;
    width: 110px !important
}

.i_a2 {
    background-position: -212px -252px
}

.i_a3 {
    background-position: -212px -276px
}

.mi_d1,
.mi_d2,
.mi_d3 {
    height: 24px;
    line-height: 24px;
    margin-bottom: 4px;
    white-space: nowrap;
    display: flex;
}

.mi_d1 span,
.mi_d2 span,
.mi_d3 span {
    padding-right: 12px;
    z-index: 112
}

.mi_d {
    width: 440px
}

.mi_g {
    width: auto
}

.mi_d2 {
    background: #f9fff0
}

.mi_b {
    padding-right: 23px;
    z-index: 11
}

.mi_1 {
    padding-right: 33px
}

.mi_web {
    width: 800px
}

.mi_d3 a:hover {
    color: #fb6400
}

.prompt {
    background: url(../../assets/images/prompt.gif) no-repeat;
    width: 13px;
    height: 13px;
    top: 0;
    right: 0
}

.p-bg {
    background: url(../../assets/images/p-bg.png) no-repeat;
    width: 121px;
    padding: 4px 3px 0 9px;
    height: 104px;
    line-height: 18px;
    top: 2px;
    right: -128px;
    z-index: 111;
    display: none
}

.p-bg img {
    width: 120px;
    height: 80px
}

.mi-lr {
    width: 489px
}

.mi_tit {
    background-position: -149px -58px;
    width: 32px;
    color: #6aaa05;
    padding-left: 4px;
    height: 18px;
    line-height: 18px;
    margin: 3px 8px 0 5px;
    background-color: #6aaa05;
    
}

.game_w,
.h5_w {
    background-position: -497px -285px;
    width: 58px;
    padding-right: 5px;
    height: 18px;
    line-height: 18px;
    margin: 3px 8px 0 5px
}

.game_w {
    color: #ed7811
}

.h5_w {
    background-position: -497px -334px;
    color: #429bec
}

.more {
    margin-right: 5px !important;
    color: #8f8f8f !important
}

.more:hover {
    color: #f60 !important
}

.more_1 {
    background-position: 0 -247px;
    width: 38px;
    height: 13px;
    margin: 10px 8px 0 0;
    line-height: 100px
}

.more_1:hover {
    background-position: 0 -260px
}

.s-link {
    font: 12px/14px \5B8B\4F53;
    height: 14px;
    color: #448230;
    margin: 10px 10px 0 0;
    padding-right: 10px;
    border-right: 1px solid #4e4e4e
}

.fb6 {
    color: #fb6400 !important
}

.f38 {
    color: #389904 !important
}

.caro {
    background-position: -360px -308px;
    width: 240px;
    padding-left: 15px;
    height: 24px
}

.caro li {
    height: 24px
}

.caro a {
    color: #389904
}

.caro a:hover {
    color: #fb6400
}

.bgbox {
    background: #fff;
    width: 980px;
    padding-top: 5px
}

.ip_game {
    background-position: -108px -224px;
    width: 70px;
    padding-left: 27px;
    height: 42px;
    margin-right: 6px
}

#hscontent {
    width: 770px;
    white-space: nowrap;
    word-spacing: 4px;
    height: 42px;
    margin-right: 10px
}

.mask {
    background: url(../../assets/images/mask.png) no-repeat;
    width: 30px;
    height: 42px;
    top: 0;
    right: 105px;
    _display: none
}

#hscontent img {
    margin: 3px 5px 0 0
}

#hscontentdiv a {
    vertical-align: middle;
    height: 42px;
    color: #389904;
    margin-left: 5px
}

#hscontentdiv span {
    vertical-align: middle;
    width: 13px;
    height: 13px
}

#hscontentdiv a:hover {
    color: #f60
}

.clo {
    background: url(../../assets/images/close7.gif) no-repeat
}

.hsl_n,
.hsl_y,
.hsr_n,
.hsr_y {
    width: 12px;
    height: 22px;
    margin: 10px 4px 0 4px
}

.hsl_n {
    background-position: -0 -92px
}

.hsl_y {
    background-position: -12px -92px
}

.hsr_n {
    background-position: -0 -114px
}

.hsr_y {
    background-position: -12px -114px
}

.empty {
    background: #d2f2a5;
    color: #52a21c;
    width: 46px;
    height: 42px;
    line-height: 42px
}

.empty:hover {
    background: #7bd109
}

.rollgame {
    background: #fff;
    border: 1px solid #c0eb7c;
    width: 978px;
    height: 146px;
    border: 1px solid #c0eb7c
}

.ulleft {
    width: 1872px;
    height: 144px
}

.g_le,
.g_ri {
    width: 15px;
    height: 68px;
    margin-top: 35px
}

.g_le {
    background-position: 0 -151px
}

.g_ri {
    background-position: -15px -151px
}

.game_box {
    height: 140px;
    margin-left: 7px;
    padding-top: 6px;
    width: 938px
}

.g_list {
    width: 1872px
}

.g_list li {
    width: 72px;
    height: 63px;
    line-height: 20px;
    margin: 3px 3px 2px 3px
}

.g_list img {
    width: 63px;
    height: 42px
}

.middle_3 {
    background: #fff;
    width: 980px;
    margin: 10px auto
}

.rec_left,
.tm_fun,
.rec_top {
    background: #fff;
    border: 1px solid #c0eb7c
}

.h_1 {
    height: 410px
}

.h_2 {
    height: 1300px;
    margin-top: 10px
}

.h_3 {
    height: 1905px
}

.h_4 {
    height: 240px;
    margin-top: 10px
}

.box_l {
    width: 248px;
    margin-left: 10px
}

.box_c {
    width: 722px
}

.rec_left {
    width: 246px
}

.rec_ul {
    width: 218px;
    padding: 0 0 13px 13px
}

.rec_ul li {
    width: 218px;
    height: 55px;
    margin-top: 12px
}

.rec_img {
    width: 55px
}

.rec_img img {
    width: 55px;
    height: 55px
}

.rec_ul p {
    width: 158px;
    padding-left: 5px;
    line-height: 18px
}

.rec_ul p em {
    display: block;
    height: 24px
}

.p_ac {
    margin-top: 5px;
    font-size: 12px;
    line-height: 16px;
    color: #929292
}

.tit_ico1 {
    background-position: 0 -224px;
    width: 16px;
    height: 16px;
    margin: 8px 5px 0 10px
}

.tit_ico2 {
    background-position: 0 -279px;
    width: 9px;
    height: 32px;
    margin-right: 5px
}

.tit_ico3 {
    background-position: -157px -331px;
    width: 10px;
    height: 16px;
    margin: 8px 6px 0 6px
}

.phone {
    background-position: -33px -122px;
    width: 14px;
    height: 26px;
    margin: 4px 6px 0 6px
}

.tit_ico4 {
    background-position: -171px -331px;
    width: 16px;
    height: 16px;
    margin: 7px 5px 0 5px
}

.tit_ico5 {
    background-position: 0 -518px;
    width: 102px;
    height: 32px;
    margin-right: 15px
}

.asp_box {
    width: 246px;
    margin-top: 10px;
    border: 1px solid #c0eb7c
}

.asp_week {
    border-top: 1px solid #c0eb7c;
    border-bottom: 1px solid #c0eb7c
}

.tit_a {
    font-weight: bold;
    color: #448230
}

.asp_img {
    width: 220px;
    height: 120px;
    margin: 10px auto 0
}

.evn_box {
    width: 720px;
    height: 148px;
    border: 1px solid #8ddcf7;
    margin-top: 10px
}

.evn_week {
    background: #d4f4ff;
    height: 32px;
    line-height: 32px;
    font-weight: bold;
    border-top: 1px solid #8ddcf7;
    border-bottom: 1px solid #8ddcf7
}

.evn_week a {
    color: #4e4e4e
}

.evn_week a:hover {
    color: #f60
}

.phone_ul {
    padding-top: 10px
}

.phone_ul li {
    width: 66px;
    height: 90px;
    line-height: 22px;
    margin-left: 11px
}

.phone_ul img {
    width: 66px;
    height: 66px
}

.evn_ul {
    padding: 10px 0 0 10px
}

.evn_ul li {
    width: 145px;
    height: 95px;
    margin: 0 15px
}

.evn_ul img {
    width: 145px;
    height: 70px
}

.tm_fun {
    width: 720px
}

.more_2,
.more_3 {
    width: 38px;
    line-height: 100px;
    height: 13px;
    margin: 10px 9px 0 0
}

.more_2 {
    background-position: -942px -323px
}

.more_2:hover {
    background-position: -942px -336px
}

.more_3 {
    background-position: -942px -350px
}

.more_3:hover {
    background-position: -942px -363px
}

.new_ico {
    background-position: -360px -332px;
    width: 26px;
    height: 11px;
    margin: 10px 5px 0 0
}

.tm_list {
    padding: 7px 0 0 5px
}

.tm_list li {
    width: 95px;
    height: 95px;
    padding-top: 3px;
    line-height: 22px;
    margin-left: 6px
}

.tm_list img {
    width: 72px;
    height: 72px
}

.rec_top {
    width: 246px;
    margin-top: 10px;
    padding-bottom: 10px
}

.top_ul {
    margin: 10px 0 0 2px
}

.top_ul li {
    width: 80px;
    height: 86px;
    line-height: 20px
}

.top_ul img {
    width: 72px;
    height: 62px
}

.qz_ul {
    width: 218px;
    height: 365px;
    padding: 0 0 10px 10px
}

.qz_ul li {
    background-position: 0 -405px;
    width: 228px;
    height: 82px;
    margin-top: 10px
}

.qz_img {
    width: 75px
}

.qz_img img {
    width: 75px;
    height: 75px
}

.qz_ul p {
    width: 130px;
    padding-left: 5px;
    line-height: 20px
}

.qz_ul .em {
    display: block;
    width: 130px;
    height: 48px
}

.wd_ul {
    padding-top: 10px
}

.wd_ul li {
    width: 106px;
    height: 93px;
    margin-left: 11px
}

.wd_ul img {
    width: 106px;
    height: 64px
}

.lf_game,
.old-g,
.games,
.links,
.copy {
    background: #fff;
    border: 1px solid #c0eb7c;
    width: 978px;
    padding-bottom: 8px;
    border: 1px solid #c0eb7c;
    margin: 10px auto
}

.g_line {
    background: #f5ffe4;
    border: 1px solid #c0eb7c;
    color: #389904;
    top: -5px;
    left: 10px;
    width: 92px;
    height: 24px;
    line-height: 22px
}

.lf_ul {
    padding-top: 15px
}

.lf_ul li {
    margin-left: 11px;
    width: 96px
}

.g-zone {
    width: 980px
}

.g-top {
    background-position: 0 -100px;
    width: 960px;
    padding-left: 20px;
    height: 33px;
    line-height: 33px
}

.g-top .tit {
    color: #f228c0;
    font-weight: bold
}

.g-cen {
    width: 978px;
    border: 1px solid #f9c3e2;
    border-top: none
}

.g-le {
    width: 700px
}

.g-zone .t1,
.g-zone .t2 {
    height: 18px;
    line-height: 16px;
    font-weight: bold;
    padding-left: 20px;
    margin: 10px 0 0 10px
}

.g-zone .t1 a,
.g-zone .t2 a {
    color: #f228c0
}

.g-zone .t1 {
    background-position: 0 -143px;
    width: 75px
}

.g-zone .t2 {
    background-position: -95px -143px;
    width: 105px
}

.g-zone .m1 {
    margin: 13px 0 0 0
}

.g-zone .m2 {
    margin: 20px 0 3px 0
}

.g-ul {
    width: 750px;
    padding-top: 10px
}

.g-ul li {
    margin: 0 2px;
    height: 98px
}

.w-game {
    padding: 10px 0 10px 5px
}

.w-game li {
    width: 112px;
    height: 98px;
    margin: 0 13px;
    padding-top: 3px
}

.w-game img {
    width: 112px;
    height: 72px
}

.g-ri {
    width: 255px
}

.setlide {
    width: 238px;
    border: 1px solid #f8c3e2;
    padding: 1px;
    margin-top: 10px
}

.setimgs {
    width: 238px;
    height: 132px
}

.setimgs li,
.setimgs img {
    width: 238px;
    height: 132px
}

.setlide .up,
.setlide .do {
    width: 28px;
    height: 44px;
    top: 40px;
    opacity: .6;
    filter: alpha(opacity=60)
}

.setlide .up {
    background-position: 0 -168px;
    left: 1px
}

.setlide .up:hover {
    opacity: .9;
    filter: alpha(opacity=90)
}

.setlide .do {
    background-position: -28px -168px;
    right: 1px
}

.setlide .do:hover {
    opacity: .9;
    filter: alpha(opacity=90)
}

.settit {
    width: 238px;
    height: 25px;
    left: 1px;
    bottom: 1px;
    z-index: 2;
    background: rgba(0, 0, 0, .7);
    filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr='#b2000000', EndColorStr='#b2000000')
}

.settit a {
    padding-left: 5px;
    width: 240px;
    height: 25px
}

.s-tex {
    width: 255px;
    height: 52px
}

.s-tex a {
    background: #ededed;
    width: 118px;
    height: 24px;
    line-height: 24px;
    left: 0
}

.tm_tit,
.top_tit {
    background: #eeffd9;
    height: 32px;
    line-height: 32px
}

.top_tit em {
    float: left;
    font-size: 12px;
    color: #448230;
    padding-left: 10px
}

.tm_upd {
    font-family: \5B8B\4F53;
    font-size: 12px
}

.tm_upd {
    color: #a6d45e
}

.tm_upd span {
    font-weight: bold;
    color: #f60;
    text-decoration: underline
}

.tm_upd a {
    color: #448230
}

.tm_upd a:hover {
    color: #f60
}

.g-old {
    height: 72px
}

.old-g {
    height: 677px
}

.g-ico {
    background-position: right -58px;
    width: 53px;
    height: 83px;
    top: -11px;
    left: 15px
}

.o-tit {
    font-size: 16px;
    color: #448230;
    font-weight: bold;
    left: 80px;
    top: 3px
}

.old-line {
    display: inline;
    width: 890px;
    margin: 8px 0 0 80px
}

.old-line a {
    background: #fff;
    height: 22px;
    line-height: 22px;
    border: 1px solid #c0eb7c;
    padding: 0 5px;
    margin-right: 5px
}

.o-ul {
    width: 712px;
    padding: 15px 0 0 5px;
    display: inline
}

.o-ul li {
    margin-left: 5px;
    width: 96px
}

.old-ri {
    width: 245px;
    height: 596px;
    margin: 5px 8px 0 0
}

.old-t1,
.old-t2 {
    width: 235px;
    height: 32px;
    margin: 14px auto 10px auto
}

.old-t1 {
    background-position: right -290px
}

.old-t2 {
    background-position: right -323px
}

.o-bor1,
.o-bor2 {
    width: 240px;
    height: 180px;
    border: 1px solid #c0eb7c
}

.o-bor2 {
    height: 300px
}

.old-a {
    line-height: 34px;
    margin: 10px 0 5px 0
}

.old-a img {
    width: 220px;
    height: 130px
}

.or-ul {
    padding: 10px 0 0 2px
}

.or-ul li {
    width: 106px;
    height: 96px;
    margin-left: 8px;
    line-height: 26px
}

.or-ul img {
    width: 106px;
    height: 64px
}

.fenl_lbox,
.fenl_rbox {
    background: #fff;
    width: 483px;
    height: 255px;
    border: 1px solid #c0eb7c
}

.boxes {
    width: 480px;
    height: 210px
}

.fenl {
    width: 980px;
    margin: 10px auto
}

.fenl_ul {
    padding: 15px 0 0 8px;
    width: 480px
}

.fenl_ul li {
    width: 95px;
    height: 90px;
    padding-top: 3px;
    line-height: 18px;
    margin-bottom: 10px
}

.fenl_ul img {
    width: 72px;
    height: 72px
}

.s_tab {
    padding: 19px 0 0 45px
}

.s_tab li {
    background: #f7ffec;
    color: #4ca127;
    border: 1px solid #c0eb7c;
    width: 58px;
    height: 27px;
    line-height: 27px;
    border-bottom: 0;
    margin-right: 2px
}

.s_tab li.tab_on {
    background-position: -32px -151px;
    background-color: #fff;
    height: 32px;
    line-height: 32px;
    margin-top: -4px
}

.star_tab {
    background-position: 0 -600px;
    width: 980px;
    margin: -10px auto 0;
    height: 48px
}

.s_le {
    background-position: 0 -552px;
    background-repeat: no-repeat
}

.s_ri {
    background-position: 0 -648px;
    width: 1px;
    height: 48px
}

.lt {
    width: 940px
}

.tit-n {
    height: 20px;
    line-height: 20px;
    margin: 15px 0 10px 0
}

.tit-n em {
    width: 18px;
    height: 18px;
    margin: 1px 5px 0 0
}

.tit-n .ico1 {
    background-position: -205px -224px
}

.tit-n .ico2 {
    background-position: -224px -224px
}

.tit-n .ico3 {
    background-position: -243px -224px
}

.tit-n .ico4 {
    background-position: -205px -243px
}

.tit-n .tit {
    font-weight: bold
}

.lt-l1 {
    width: 645px;
    height: 477px;
    float: left;
    overflow: hidden
}

.lt-tab {
    padding-left: 20px
}

.lt-tab a {
    background-position: -206px -159px;
    width: 70px;
    height: 20px;
    color: #4f780d;
    margin-left: 12px
}

.lt-tab a:hover,
.lt-tab a.on {
    background-position: -206px -180px
}

.lt-list {
    width: 660px;
    height: 432px;
    margin-left: -15px
}

.lt-list li {
    width: 150px;
    height: 147px;
    margin-left: 15px;
    line-height: 20px
}

.lt-list img {
    width: 150px;
    height: 94px
}

.lt-list .a a {
    color: #999
}

.lt-list a:hover {
    color: #fb6400
}

.lt-list a em {
    height: 18px;
    line-height: 18px;
    padding: 0 5px;
    right: 0;
    top: 0
}

.lt-list .b1 {
    background: #ff705c
}

.lt-list .b2 {
    background: #74bcea
}

.lt-list .b3 {
    background: #6aaa05
}

.lt-r1,
.v-top {
    width: 255px
}

.v-top li {
    width: 255px;
    height: 20px;
    line-height: 20px;
    border-bottom: 1px solid #e9e9e9;
    padding: 10px 0
}

.v-top em {
    background: #c2c2c2;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-right: 10px
}

.v-top .c1,
.v-top .c2,
.v-top .c3 {
    height: 58px;
    line-height: 58px
}

.v-top .c1 {
    background: #ff3d3d
}

.v-top .c2 {
    background: #ff774c
}

.v-top .c3 {
    background: #ff9c46
}

.v-top .img {
    width: 93px;
    height: 58px;
    margin-right: 10px
}

.v-top .tex {
    width: 120px;
    height: 32px;
    font-size: 12px;
    line-height: 16px
}

.v-top .btn {
    height: 18px;
    line-height: 18px;
    color: #999;
    font-size: 12px;
    padding: 0 5px;
    border: 1px solid #d5d5d5;
    border-radius: 3px;
    margin-top: 5px
}

.v-top .hover {
    height: 58px
}

.v-top .p0 {
    padding-top: 0
}

.v-top a:hover {
    color: #fb6400
}

#txt3 {
    height: 37px;
    border-bottom: none
}

.lr-line {
    padding-top: 10px;
    height: 120px
}

.lr-line a {
    background: #ededed;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    margin: 1px
}

.lr-line a:hover {
    background: #71bc16
}

.corner {
    background: url(../../assets/images/i2016/corner.png) no-repeat;
    width: 71px;
    height: 71px;
    top: 0;
    left: 0
}

.video {
    width: 960px;
}

.video li {
    width: 176px;
    height: 140px;
    margin-right: 15px
}

.video img {
    width: 176px;
    height: 111px
}

.video a em {
    background: #000;
    height: 20px;
    padding: 0 5px;
    line-height: 20px;
    right: 0;
    top: 91px
}

.games {
    height: 150px
}

.games_ul {
    padding: 8px 0 0 12px
}

.games_ul li {
    height: 24px;
    line-height: 24px;
    margin-right: 3px;
    width: 117px
}

.links {
    line-height: 22px;
    padding: 10px 0;
    color: #c0eb7c
}

.links a {
    color: #5da93d;
    margin: 0 3px
}

.links a:hover {
    color: #fb6400
}

.copy {
    padding: 10px 0;
    color: #8e8e8e
}

.copy a {
    color: #8e8e8e
}

.copy a:hover {
    color: #fb6400
}

.copy_t {
    background-position: 0 -438px;
    width: 960px;
    height: 60px
}

.copy_t span {
    color: #f60
}

.copy_c {
    padding-top: 10px
}

.copy_c a {
    margin: 0 5px
}

.wechat-QR {
    background: #fff;
    border: 1px solid #e1e1e1;
    width: 138px;
    height: 164px;
    line-height: 14px;
    top: -90px;
    _top: -95px;
    right: 148px;
    _right: 150px
}

.wechat-QR img {
    margin: 7px auto 3px auto
}

.fk {
    color: #ff7c2d !important
}

.fk em {
    background-position: -531px -260px;
    width: 12px;
    height: 12px;
    vertical-align: middle;
    margin-right: 3px
}

.wb,
.tx,
.ghs {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin: 0 6px 0 0 !important
}

.wb {
    background-position: -497px -260px
}

.tx {
    background-position: -514px -260px
}

.moc {
    background-position: -544px -260px;
    width: 22px;
    height: 22px;
    vertical-align: middle;
    margin: 0 !important
}

.ghs {
    background-position: -568px -260px;
    width: 20px;
    height: 20px
}

.skin_pop {
    background: #fff;
    width: 460px;
    height: 400px;
    opacity: .75;
    filter: alpha(opacity=75);
    left: 50%;
    margin-left: -200px;
    top: 100px;
    z-index: 100
}

.skin_box {
    background: #fff;
    width: 450px;
    height: 390px;
    border: 1px solid #cacac9;
    left: 50%;
    top: 104px;
    margin-left: -196px;
    z-index: 101
}

.skin_top {
    background-position: 0 -32px;
    height: 37px
}

.skin_tab {
    height: 33px
}

.skin_tab a {
    width: 88px;
    height: 32px;
    line-height: 32px;
    color: #565656;
    border-left: 1px solid #fff;
    border-right: 1px solid #dbdbdb
}

.skin_tab a:hover,
.skin_tab .on {
    background-position: -557px -66px;
    background-color: #f7fee3;
    height: 33px !important;
    color: #171e03 !important;
    text-decoration: none
}

.skin_ul {
    height: 300px
}

.skin_ul li {
    width: 100px;
    height: 90px;
    margin: 6px 0 0 10px
}

.skin_ul li img {
    height: 70px;
    width: 100px
}

.i_sel {
    background-position: -138px -69px;
    width: 18px;
    height: 18px;
    right: 3px;
    top: 3px
}

.p_close {
    background-position: 0 -69px;
    width: 24px;
    height: 24px;
    margin: 4px 10px 0 0
}

.skin_box1 {
    border-top: 1px dotted #bfd1bd;
    width: 100%;
    height: 30px;
    padding-top: 10px
}

.skin_pag {
    padding-top: 8px
}

.skin_pag a {
    background-position: -40px -69px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    margin: 0 3px
}

.skin_pag a:hover,
.s_on {
    background-position: -55px -69px !important;
    text-decoration: none
}

.sk_le,
.sk_ri {
    width: 8px;
    height: 14px;
    margin: 8px 5px
}

.sk_le {
    background-position: -24px -69px;
    margin-left: 10px
}

.sk_ri {
    background-position: -32px -69px
}

.skin_btn a {
    width: 67px;
    height: 30px;
    line-height: 30px;
    font-weight: bold
}

.dr {
    background-position: -70px -69px;
    color: #fff !important
}

#skinnew {
    display: none
}

.sides {
    position: fixed;
    _bottom: auto;
    left: 50%;
    bottom: 50px;
    visibility: hidden;
    width: 59px;
    // _position: absolute;
    // _top: expression(eval(document.documentElement.scrollTop+document.documentElement.clientHeight-this.offsetHeight-(parseInt(this.currentStyle.marginTop, 10)||0)-(parseInt(this.currentStyle.marginBottom, 10)||0)-60))
}

.s-box a {
    background: url(../../assets/images/i2017/sides_.png) no-repeat;
    width: 30px;
    height: 30px;
    padding: 9px;
    line-height: 16px;
    margin: 3px 0 3px 4px
}

.s-box a:hover {
    background-position: -48px 0
}

.s-top {
    background-position: 0 -48px !important
}

.s-top:hover {
    background-position: -48px -48px !important
}

.g-box {
    width: 70px;
    height: 70px
}

#fpage1,
#fpage2,
#fp2,
#fp3,
.wechat-QR {
    display: none
}



html {
    background-image: url("about:blank");
    background-attachment: fixed;
}

.xndgg {
    display: none;
    z-index: 99999;
    width: 300px;
    height: 320px;
    position: fixed;
    bottom: 0;
    right: 0;
    overflow: hidden;
    _position: absolute;
    // _top: expression(documentElement.scrollTop+documentElement.clientHeight-this.offsetHeight);
}

.xndgg a {
    display: block;
    overflow: hidden;
}

.xndgg .xndclose {
    background: url("../../assets/images/abclo2.png") no-repeat;
    background-position: 0 0;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 10;
}

.xndgg .xndclose:hover {
    background-position: 0 -30px;
}

.xndgg .xndimg {
    width: 300px;
    height: 320px;
    position: relative;
    z-index: 5;
}

.xndgg .xndimg img {
    width: 300px;
    height: 320px;
    display: block;
    margin: 0 auto;
}

.xndgg .xndimg i,
.xndgg .xndimg span {
    height: 40px;
    overflow: hidden;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.xndgg .xndimg i {
    background: url("../../assets/images/xndbg.png") no-repeat;
    background-repeat: repeat;
    _background: #000;
    width: 100%;
    z-index: 2;
}

.xndgg .xndimg span {
    width: 270px;
    line-height: 40px;
    left: 15px;
    z-index: 4;
    font-size: 16px;
    color: #fff;
}

.xndgg .xndimg:hover,
.xndgg .xndimg:hover span {
    text-decoration: none;
    color: #fb6400;
}

/*202305*/

.yun_list li {
    padding-top: 5px;
}

.yun_style {
    height: 1653px;
}

.yun_tit {
    padding-left: 14px;
}



.box {
	clear: both;
	display: block;
	float: none;
	margin: 0 auto;
	width: 970px;
	height: auto
}

.banner {
	height: 40px;
}

#bannerU {
	border-style:dotted;
	float: right;
	height: 33px;
	width: 80%;
	text-align:right;
}

.banner span {
	float: left;
}

#rec1 {
	border-style:dotted;
	float: left;
	width: 306px;
	height: 380px;
    text-align:center;
}

#rec2 {
	border-style:dotted;
	float: right;
	width: 635px;
	height: 400px;
	text-align:center;
}
.rec3 {
    display: block;
    float: none;
    height: 1px;
    overflow: hidden;
}


#rec4 {
	border-style:dotted;
	float: right;
	width: 635px;
	height: 800px;
	text-align:center;
}
#rec5 {
	border-style:dotted;
	float: right;
	width: 635px;
	height: 800px;
	text-align:center;
}
#rec6 {
	border-style:dotted;
	float: right;
	width: 635px;
	height: 800px;
	text-align:center;
}


.mi_block{
    display: block;
}

.fs12{
    font-size: 12px;
}

.rec_ul{
    a{
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .p_ac{
        line-height: 16px;
    }
}